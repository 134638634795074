export const termsContent = String.raw`# MY BREEDERS STORE PLATFORM TERMS AND CONDITIONS


# MY BREEDERS STORE PLATFORM TERMS AND CONDITIONS

## 1. Introduction

1. The My Breeders Store Platform Terms and Conditions, together with our Privacy Policy, comprise the terms of a legal agreement between the person listed in the Registration Portal (**you** or **your**) and The Breeders Store Pty Ltd (ACN 655 079 137) (**Breeders Store, we**, **our** or **us**) (collectively, the **agreement**).

2. By accessing and using the My Breeders Store Platform, you warrant that you have read this agreement, and agree to be bound by this agreement. If you are using the My Breeders Store Platform as a representative of an entity, you are agreeing to the agreement on behalf of that entity.

## 2. My Breeders Store Platform

1. The My Breeders Store Platform is a portal or online marketplace through which Buyers can order and purchase Pets and Pet Accessories made available by Breeders or Suppliers on, or via, the My Breeders Store Platform. We are a mere online marketplace or conduit for introducing Buyers, Breeders and Suppliers and we use Third Party Payment Processors to facility the sale of Pets and/or Pet Accessories by Breeders and/r Suppliers to Buyers on, or via, the My Breeders Store Platform. We are not a breeder of Pets or a supplier, distributor or manufacturer of Pet Accessories, consumer or buyer of Pets and/or Pet Accessories or a courier or delivery service provider, and our role in providing you with access and use of the My Breeders Store Platform should be construed strictly in this context only.

2. When you create an Account on the My Breeders Store Platform, Breeders Store grants you a non-exclusive, non-transferable, personal, revocable and non-sublicensable licence to download, access and use the My Breeders Store Platform for the Term. You will not acquire or be entitled to any rights other than those rights expressly set out in this agreement.

## 3. Changes

We reserve the right to change any provision of this agreement in any way and we may, in our absolute discretion, add, change or remove any functions or features of the My Breeders Store Platform, Buyer, Breeder or Supplier, Pet, Pet Accessories, our Subscription Fees and/or Service Fee or any other ancillary products or services offered on, or via, the My Breeders Store Platform with, or without notice, to you. If we provide you with notice of changes to this agreement, we shall do so either by email or by posting it on the My Breeders Store Platform.

## 4. Term

1. This agreement commences on the date you create an Account with us on, or via, the My Breeders Store Platform (**Commencement Date**) and continues, unless terminated in accordance with clause 18 (**Term**), unless you (as a Breeder and/or Supplier) terminate the agreement during the Trial Period in accordance with clause 4.2.

2. If you (as a Breeder and/or Supplier) do not wish to continue to access and use the My Breeders Store Platform following the expiry of the Trial Period, you (as a Breeder and/or Supplier) must terminate the agreement by selecting the 'delete account' function in the profile setting page or by cancelling the subscription in the Apple Store subscription page prior to the expiry of the Trial Period. If you (as a Breeder and/or Supplier) do not terminate the agreement prior to the expiry of the Trial Period, the agreement will automatically continue for the Term.

## 5. Account

1. If you wish to access and use the My Breeders Store Platform:

   a) as a Breeder and/or Supplier, you must create an account with us via the Registration Portal (**Breeder Account**); or

   b) as a Buyer, you can either enter as a guest or create an account with us via the Registration Portal (**Buyer Account**).

2. Your Breeder Account or Buyer Account (**Account**) will be operated by a username and password (**Password**). You may select your own username and Password, which you may change online at your convenience. The owner of the Account is solely responsible for the activity conducted on the Account. We may at any time request that the owner of the Account provide identification to verify their identity.

3. In order to open an Account or otherwise access and use the My Breeders Store Platform you must:

   a) be over 18 years of age, and legally able to enter into contractual relations. If you are under the age of 18 years, you may only access and use the My Breeders Store Platform if you have your parents'/guardians' permission to download, access and use the My Breeders Store Platform. Your continued use of the Account constitutes an acknowledgement by you that you are: (i) over 18 years of age; or (ii) under 18 years of age, but have your parents'/guardians' permission to access and use the My Breeders Store Platform and that your parents/ guardians have agreed to abide by this agreement; and

   b) provide accurate and complete registration information as described in clause 7 (if you are a Breeder or Supplier) or clause 8 (if you are a Buyer). All Personal Information as well as the information you provide to setup your Account is subject to our Privacy Policy.

4. In relation to your Account, you are responsible for:

   a) maintaining control over, and the confidentiality of, your Account and Password;

   b) keeping your Account registration information current, complete, accurate, and truthful. You must not impersonate another account holder or provide false identity information to gain access to or use the My Breeders Store Platform;

   c) notifying us in writing of any unauthorised access to, or use of, your Account or Password; and

   d) for all activities or transactions that occur using your Account. We are not liable for any loss or damage arising out of, or in connection with, any unauthorised access or use of, your Account and Password.

5. Breeders Store has the right to suspend or terminate any Account or Password or your access or use of all or any part of the My Breeders Store Platform, at any time, if, in our opinion, you have failed to comply with any of the provisions of this agreement.

## 6. Your general obligations

1. To access and use the My Breeders Store Platform, you must:

   a) open an Account with us via the My Breeders Store Platform;

   b) provide us with all necessary co-operation and information to facilitate and provide the My Breeders Store Platform including (but not limited to) proof of identity;

   c) ensure that all information is true, accurate and not misleading;

   d) obtain and maintain all equipment, hardware and software required by you to use and/or access the My Breeders Store Platform;

   e) comply with all reasonable directions, policies and guidelines of Breeders Store as advised from time to time; and

   f) carry out all of your responsibilities set out in this agreement in a timely and efficient manner.

## 7. Breeder and Supplier obligations

1. At the time a Breeder and/or Supplier creates a Breeder Account with us, the Breeder and/or Supplier must also create a Breeder Profile, which will include your business information and Personal Information, such as:

   a) your business name, business logo or banner, profile picture, ABN, main business contact details (including contact person, email address and location), a copy of your Breeder ID Card (if applicable) and and a description of your Pet and/pr Pet Accessories business. You are also required to provide your bank account details our Third Party Payment Processors;

   b) a description and photographs of the Pets and Pet Accessories to be offered for sale on, or via, the My Breeders Store Platform and the corresponding price for such Pets and Pet Accessories; and

   c) any other information that Breeders Store may require to verify your identity, Breeder Profile and the accuracy of any advertisements displayed or listed on, or via, the My Breeders Store Platform.

2. At any time you may:

   a) edit your Breeder Account or update or change your Breeder Profile; or

   b) update, change, delete or add Pet and Pet Accessories advertised, listed or displayed by you on, or via, the My Breeders Store Platform.

3. Breeders Store will not be liable to you, any other Breeder, Supplier, Buyer or any third party in connection with, arising out of, any:

   a) failure to update or change your Breeder Account, Breeder Profile or Pet and/or Pet Accessories advertised, listed or displayed by you on, or via, the My Breeders Store Platform following your request; or

   b) Loss incurred by you, any other user of the My Breeders Store Platform or any third party as a result of, or in connection with, any content or information supplied by you which is uploaded, published or submitted by you to the My Breeders Store Platform.

4. During the Term, you (as a Breeder) will:

   a) hold and maintain any and all registration and licences required under Breeding Laws to breed and sell Pets in Australia;

   b) comply with all Relevant Laws when breeding and selling Pets on, or via, the My Breeders Store Platform (including, but limited to: (i) ensuring that all Pets are sterilised or neutered, immunised, examined by a veterinarian and microchipped as required by Breeding Laws; (ii) disclose to a Buyer any health or generic issues diagnosed or discovered by a veterinarian in relation to a Pet offered for sale or sold on, or via, the My Breeders Store Platform; and (iii) provide the Buyer all documents and certificates as required by Breeding Laws in relation to the Pet sold to the Buyer on, or via, the My Breeders Store Platform);

   c) ensure that the sale and supply of Pet Accessories will comply with all Relevant Laws, and you will not sell prohibited or banned Pets to Buyers;

   d) arrange for delivery of the Pet to the Buyer or for the collection of the Pet by the Buyer (as the context permits).

5. During the Term, you (as a Supplier) will:

   a) ensure any Pet Accessories supplied or sold by you will be: (i) fit for purpose, of merchantable quality and free from defects (unless otherwise disclosed to the Buyer in your Breeding Profile prior to the Buyer purchasing the Pet Accessories); and (ii) accurately described in the Breeding Profile;

   b) ensure that the sale and supply of Pet Accessories will comply with all Relevant Laws, and you will not sell Pet Accessories that are dangerous or illegal;

   c) you will complete all sales orders process though the My Breeders Store Platform or otherwise deliver all Pet Accessories to the Buyer at the Buyer's nominated address within the timeframe specified in the sales order;

   d) take care to pack the Pet Accessories carefully so that the Pet Accessories are not damaged in transit;

   e) you have all necessary permits and licences to offer, distribute, sell or supply the Pet Accessories;

   f) promptly notify the Buyer if: (i) you are unable to deliver the Pet Accessories to the Buyer; or (ii) there is any delay in delivery of the Pet Accessories to the Buyer; and

   g) at your own cost, provide the Buyer with a full refund or replace the Pet Accessories within a reasonable period of time (but no later than 30 days) if the Pet Accessories (ii) is defective, not of merchantable quality or damaged on delivery; (iii) does not meet the description, specifications and quality standards as described in the Breeder Profile; or (iv) is covered by a consumer guarantee under the Australian Consumer Law or a manufacturer's warranty applies to the Pet Accessories. This clause does not apply if the Buyer or Breeders Store cannot supply you with a proof of purchase.

6. During the Term, you (as a Breeder and/or Supplier) will:

   a) pay the Subscription Fee (except during the Trial Period) and Service Fees in accordance with this agreement;

   b) ensure that all information you display, publish and upload to your Breeder Profile in relation to Pets and Pet Accessories is true, complete and accurate. You will immediately update your Breeder Profile if you or any of the details or information you submit or upload to the My Breeders Store Platform in relation to Pets and Pet Accessories is inaccurate, incomplete or out of date (including, but not limited to, the availability of Pets and/or Pet Accessories); and

   c) not act in any manner which could bring My Breeders Store Platform and Breeders Store into disrepute.

7. You (as a Breeder and/or Supplier) acknowledge and agree that:

   a) the risk in the Pets and Pet Accessories, including from loss, theft, damage or destruction of the Pets and Pet Accessories, remains with the Breeder and/or Supplier until delivered to the Buyer or collected by the Buyer (as the case may be); and

   b) title to the Pets and Pet Accessories passes to the Buyer when the Breeder and/or Supplier receives payment of the Purchase Price from us.

8. Breeder Stores is not responsible for monitoring or censoring any advertisements or listing for the sale of Pets and Pet Accessories displayed on, or via, the My Breeders Store Platform (**Listing**); however, if you (as a Breeder and/or Supplier) fail to comply with clauses 7 or any other term of this agreement (including any of Breeder Stores' policies or guidelines published by us from time to time), Breeders Store may, at our absolute discretion and with or without notice to you:

   a) remove your Breeder Profile or Listing published or displayed on your Breeder Profile; or

   b) to restrict, suspend or terminate your Breeder Account or Breeder Profile.

9. During the Term, you (as a Breeder and/or Supplier) will not (directly or indirectly):

   a) enter into any contract, communications or negotiations with any Buyer outside of the My Breeders Store Platform for the purpose of selling Pets and/or Pet Accessories to Buyers; or

   b) circumvent the obligation to pay the Service Fee to Breeders Store when you sell Pets and/or Pet Accessories to Buyers or otherwise transact on, or via, the My Breeders Store Platform.

## 8. Buyer obligations

1. You (as a Buyer) must:

   a) unless you enter as a guest, create a Buyer Account with us via the My Breeders Store Platform. When creating a Buyer Account with Breeder Stores, the Buyer must provide accurate and complete registration information, which will include your Personal Information, such as your full name, email address, location, phone number, profile picture and address for delivery purposes; and

   b) pay the Purchase Price as displayed on the Breeder Profile and any other fees payable under this agreement when purchasing Pets and/or Pet Accessories from Breeders and/or Suppliers on, or via, the My Breeders Store Platform.

2. Before purchasing Pets from Breeders and/or Pet Accessories from Suppliers on, or via, the My Breeders Store Platform, you should carefully review the Breeder Profile and Listing.

3. We do not recommend or endorse any Breeders or Suppliers (including Breeder Profiles) or any content, comments or submissions published, posted or appearing on a Breeder Profile, the My Breeders Store Platform or our related social media pages. You acknowledge and agree that, to the maximum extent permitted by Relevant Laws:

   a) Breeders Store is not responsible for monitoring or censoring Listings; however, we may (in our absolute discretion and with notice to you) remove, modify, refuse to publish or alter the Listing if we consider that such Listing do not comply with this agreement (including any of our policies or guidelines published by us from time to time);

   b) Breeders Store makes no representations, warranties or guarantees in relation to the availability, continuity, reliability, accuracy, currency or security of the My Breeders Store Platform, the Pets, Pet Accessories or any other ancillary products or services made available to you on, or via, the My Breeders Store Platform. You acknowledge that the Pets, Pet Accessories or any other ancillary products or services made available to you on, or via, the My Breeders Store Platform are provided 'as is';

   c) it is your responsibility to determine that the Pets, Pet Accessories or any other ancillary products or services made available to you on, or via, the My Breeders Store Platform, meets your specific needs and/or are suitable for the purposes for which they are used or purchased; and

   d) Breeders Store makes no representations, warranties ord) Breeders Store makes no representations, warranties or guarantees in relation to: (i) the standard, quality or fitness for purpose of any Pets supplied by a Breeder and/or Pet Accessories supplied by a Supplier; (ii) the reliability, expertise, legitimacy, repute, or credibility of any Breeder or Supplier; or (ii) the Breeders or Suppliers ability or willingness to sell, provide or deliver the Pets and/or Pet Accessories to you.

4. During the Term, you (as a Buyer) will not (directly or indirectly) enter into any contract, communications or negotiations with any Breeder and/or Supplier outside of the My Breeders Store Platform for the purpose of ordering or purchasing Pets and/or Pet Accessories from Breeders and/or Suppliers.

## 9. Restrictions

1. You must not:

   a) license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the My Breeders Store Platform available to any third party;

   b) violate Breeders Store' Intellectual Property Rights. For example, a Breeder/Supplier cannot upload or display photographs of Pets or Pet Accessories that belong to another person, unless you have consent from the copyright owner;

   c) impersonate or pose as any other person, or misrepresent your true legal identity in your dealings with us or any other user of the My Breeders Store Platform;

   d) modify, alter, adapt, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the My Breeders Store Platform or your Account, including any images contained on the My Breeders Store Platform which belong to other advertisers or us;

   e) access all or any part of the My Breeders Store Platform in order to build a product, service or code which competes with the My Breeders Store Platform; and

   f) data mine the My Breeders Store Platform, conduct penetration testing of the My Breeders Store Platform nor access, store, distribute or transmit any viruses, worms, trojans or other malicious code, or any material when using the My Breeders Store Platform that is unlawful, unethical, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically offensive or a contravention of the rights of any third party or corrupts, damages, degrades or disrupts the operation of the My Breeders Store Platform.

2. We reserve the right to limit your use of the My Breeders Store Platform, your right to purchase or advertise Pets and/or Pet Accessories and/or any other ancillary products or services made available to you on, or via, the My Breeders Store Platform, including the right to restrict, suspend or terminate your Account or Breeder Profile if we believe you are in breach of this agreement or you are misusing the My Breeders Store Platform.

## 10. Breeders Store obligations

1. Breeders Store shall use reasonable endeavours to provide you with:

   a) access and use of the My Breeders Store Platform; and

   b) payment collection services when a Buyer and Breeder and/or Supplier transact on, or via, the My Breeders Store Platform.

2. In the event that Breeders Store fails to provide you with access and use of the My Breeders Store Platform in accordance with clause 10.1, we will use reasonable endeavours to correct any such non-conformance, or provide you with an alternative means of accomplishing the desired performance. The foregoing constitutes your sole and exclusive remedy for any breach of clause 10.1.

## 11. Fees and Purchase Price

1. Except during the Trial Period, to access and use the My Breeders Store Platform, a Breeder and/or Supplier must pay an annual subscription fee as specified on the My Breeders Store Platform (**Subscription Fee**). We do not charge a fee for a Buyer to download and install the My Breeders Store Platform or to create a Buyer Account.

2. When a Buyer orders or purchases Pets and/or Pet Accessories offered for sale by a Breeder and/or Supplier on, or via, the My Breeders Store Platform (**sales order**), our Third Party Payment Processors will charge the Buyer the full price to purchase the Pets and/or Pet Accessories as displayed on My Breeders Store Platform (**Purchase Price**).

3. In addition to Subscription Fee, the Breeder/Supplier must pay us a service fee equal to 5% of the total value of the Pets and/or Pet Accessories ordered or purchased on, or via, the My Breeders Store Platform (inclusive of Third Party Payment Processor fees) (**Service Fee**), per transaction or each time a Breeder/Supplier provides or sells Pets and/or Pet Accessories to a Buyer or otherwise transact with a Buyer on, or via, the My Breeders Store Platform.

4. The Subscription Fees, Service Fees and Purchase Price displayed on the My Breeders Store Platform are current at the time of issue. The Purchase Price and availability of Pets and/or Pet Accessories and any other ancillary products or services made available to you on, or via, the My Breeders Store Platform, are subject to change effective immediately upon posting to the My Breeders Store Platform. You agree to pay the price current at the time of payment.

5. The Purchase Price for Pets and/or Pet Accessories displayed on third party websites which are linked to the My Breeders Store Platform may not be correct and Breeders Store are not bound by them.

6. Breeders Store will collect the Purchase Price from the Buyer and we will pay the Breeder/Supplier the Purchase Price (after deducting Service Fee from the Purchase Price), within five days of receiving payment of the Purchase Price from the Buyer, provided no complaint has been raised by the Buyer in writing in accordance with clause 11.9(c).

7. A sales order will be deemed complete when both the Breeder/Supplier marks the sales order as 'delivered' in the My Breeders Store Platform and the Buyer marks the sales order as 'received' in the My Breeders Store Platform. If the Buyer does not mark the sales order as 'received' within five days of the Breeder/Supplier marking the sales order as 'delivered', the sales order will be deemed to be completed, unless the Buyer raises a complaint in relation to the sales order in accordance with clause 11.9(c).

8. If a sales order is not delivered to the Buyer within 14 days of the date the sales order was created (as evidenced by the Breeder/Supplier not marking the sales order as 'delivered' and the Buyer not marking the sales order as 'received'), the sales order will be automatically cancelled, and the Buyer will receive a full refund of the Purchase Price.

9. Breeders Store is under no obligation to pay the Breeder/Supplier the Purchase Price (less the Service Fee) if:

   a) the Buyer does not pay us for the Pets and/or Pet Accessories supplied by the Breeder/Supplier for whatever reason;

   b) the Breeder/Supplier fails to provide the Pets and/or Pet Accessories to the Buyer for whatever reason; or

   c) we receive a written complaint from the Buyer within: (i) 5 days (in the event of non-delivery); or (ii) 2 days (in all other cases), after the scheduled delivery of the Pets and/or Pet Accessories to the Buyer, that: (i) the Pets and/or Pet Accessories provided by the Breeder/Supplier were not provided or supplied to the Buyer's reasonable satisfaction; or (ii) the Pets and/or Pet Accessories did not fit the description as displayed on the Breeder Profile; or (iii) the Pets and/or Pet Accessories were not delivered within a reasonable timeframe or at all. A Buyer cannot complain, reject or return a Pet and/or Pet Accessories because the Buyer has changed their mind. We may, at our absolute discretion, with or without notice, remove your Listings and/or Breeder Profile from the My Breeders Store Platform.

10. We reserve the right to vary the Subscription Fee and/or Service Fee by providing you with 14 days' written notice of these changes. If you do not agree to these changes, you may terminate this agreement no later 14 days after you have received written notice of the new fees. If Breeders Store does not receive the written notice within 14 days, you will have deemed to have accepted the new fees.

## 12. Payment

1. All fees shown on the My Breeders Store Platform are in Australian Dollars (AUD) and are inclusive of GST.

2. To the maximum extent permitted by Relevant Laws and unless otherwise stipulated in this agreement, you agree that all Subscription Fees, Service Fees, and/or Purchase Price (as the context permits) or any other amounts owing to us under the agreement are non-refundable.

3. A Buyer must pay the Subscription Fees, Service Fees, Purchase Price or any other amounts owing to us under the agreement by debit card or credit card (Visa or Mastercard). We use a Third Party Payment Processors to process payments made to us. By making payment of the Subscription Fees, Service Fees, Purchase Price or any other amounts owing to us under the agreement, you agree to provide Breeders Store with accurate and complete billing information, and you authorise us to your share billing information with our Third Party Payment Processor for the purpose of processing your payment.

4. We may, at our absolute discretion and without notice to you, change the payment methods that can be used to access and use the My Breeders Store Platform at any time.

## 13. Breeder Content Policy

1. By advertising, uploading, transmitting, posting or publishing your Breeder Profile and any Listings (including any videos, content, materials, works, text, graphics, photographs, images, and other data) (collectively, **Breeder Content**) to the My Breeders Store Platform, you (as a Breeder and/or Supplier) warrant that at all times:

   a) you hold all necessary rights, licences and consents to upload or publish the Breeder Content on, or via, the My Breeders Store Platform;

   b) nothing in the Breeder Content will cause you or us (or anyone else) to breach any Relevant Laws;

   c) no Breeder Content is or could reasonably be considered to be obscene, inappropriate, defamatory, disparaging, indecent, seditious, offensive, pornographic, threatening, abusive, liable to incite racial hatred, discriminatory, blasphemous, in breach of confidence or privacy or false and misleading;

   d) the Breeder Content does not contain, nor will they cause, any software viruses or any other computer code, files or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment;

   e) nothing in the Breeder Content would bring Breeders Store or the My Breeders Store Platform, into disrepute or adversely affect the reputation and goodwill of the My Breeders Store Platform or Breeders Store;

   f) the Breeder Content does not infringe the Intellectual Property Rights or other rights of any person; and

   g) the Breeder Content is accurate and up-to-date.

2. You (as a Breeder and/or Supplier) acknowledge and agree that Breeders Store is reliant on you for direction as to the extent Breeders Store is entitled to use, publish, transmit, market, promote, advertise or display Breeder Content or offer to sell or sell any Pet and/or Pet Accessories to Buyers and that you make available via the My Breeders Store Platform. Consequently, you warrant that, in relation to any Breeder Consent, Pet and/or Pet Accessories uploaded, published, posted or offered for sale on, or via, the My Breeders Store Platform:

   a) it has been shared, uploaded, published and posted in accordance with the Relevant Laws; and

   b) you own all Intellectual Property Rights in the Breeder Content, Pet and/or Pet Accessories, or you have a licence or have obtained the informed consent or authority from the owner of the Breeder Content, Pet and/or Pet Accessories for Breeders Store to use, publish, transmit or sell the Breeder Content, Pet and/or Pet Accessories on, or via, the My Breeders Store Platform.

3. You (as a Breeder and/or Supplier) must not publish, post, transmit or upload Breeder Content on, or via, the My Breeders Store Platform that:

   a) contain confidential information;

   b) are knowingly inaccurate, misleading or deceptive or fraudulent (including, but not limited to, as a result of you having impersonated another person or misrepresented your identity);

   c) are defamatory or harmful to another in any way, including by causing unreasonable embarrassment, annoyance, distress or alarm to another individual, including Breeders Store or our Personnel;

   d) are aimed at disparaging or damaging the reputation or goodwill of our business, the My Breeders Store Platform, our social media pages, or any of our Personnel and third parties connected to us; or

   e) constitute or comprise any other material whatsoever which contravenes this agreement.

4. Breeders Store may, at our absolute discretion, monitor, censure, remove, modify, suspend or refuse to publish or alter any Breeder Content (with or without notice to you) if:

   a) Breeders Store considers that such Breeder Content does not comply with this clause 13 (**Breeder Content Policy**), any other term of this agreement or any of Breeders Store's policies or guidelines published by Breeders Store from time to time); or

   b) Breeders Store receive a complaint from a Buyer, another Breeder, Supplier or third party that you are infringing their Intellectual Property Rights or other rights. Breeders Store may investigate any complaint within a reasonable period of time and will let you know the outcome of its investigation.

5. You may make a complaint that the Breeder Content published by another Breeder an/or Supplier contravenes the Breeder Content Policy (eg the Breeder does own the Pet for sale or the Supplier does not own copyright in the Breeder Content) by emailing us at hello@mybreedersstore.com. Breeders Store shall use reasonable endeavours to investigate the complaint and remove any Breeder Content, Pet and/or Pet Accessories from sale that has been published in contravention of the Breeder Content Policy within a reasonable period of time.

## 14. Intellectual Property Rights

1. You acknowledge that, notwithstanding anything else, Breeders Store and/or our licensors own all Intellectual Property Rights in the My Breeders Store Platform including any information, Content or technology that may be provided to, or accessed by, you in connection with your use of the My Breeders Store Platform (and anything arising or generated therefrom) (collectively, the **Breeders Store IP**). Accessing and using My Breeders Store Platform does not give you (or anyone else) ownership of, or any right, title or interest in any of the Breeders Store IP, all of which is, and will remain, owned by Breeders Store or our licensors.

2. All Intellectual Property Rights discovered, developed or otherwise coming into existence as a result of, for the purposes of, or in connection with, the My Breeders Store Platform will automatically vest in, and are assigned to, Breeders Store.

3. By creating an Account and/or publishing, uploading or submitting a Breeder Profile on, or via, the My Breeders Store Platform, you grant Breeders Store for the Term a royalty-free, non-exclusive, sub-licensable, transferable and worldwide licence to:

   a) use your Account, Breeder Content and Breeder Profile (including registration information and Personal Information) for the purpose of providing you with access to, and use of the My Breeders Store Platform and any ancillary products or services made available on, or via, the My Breeders Store Platform or for marketing purposes; and

   b) market, promote, distribute and sell Pets and/or Pet Accessories to Buyers on, or via, the My Breeders Store Platform.

## 15. Warranties

1. To the maximum extent permitted by Relevant Laws, Breeders Store excludes all express or implied representations, conditions, statutory guarantees, warranties and provisions (whether based on statute, common law or otherwise), in connection with the provision of the My Breeders Store Platform.

2. Nothing contained in this agreement excludes, restricts or modifies the application of any condition, warranty or other obligation, the exercise of any right or remedy, or the imposition of any liability under the *Competition and Consumer Act 2010 (Cth)* or any other national, State or Territory legislationwhere to do so is unlawful.

3. You acknowledge and agree that, to the extent permitted by Relevant Laws, Breeders Store makes no representations, warranties or guarantees in relation to the availability, continuity, reliability, accuracy, currency or security of the My Breeders Store Platform, the Pet and/or Pet Accessories or any other ancillary products or services made available to you on, or via, the My Breeders Store Platform. You acknowledge that the Pet and/or Pet Accessories or any other ancillary products or services made available to you on, or via, the My Breeders Store Platform are provided 'as is' and Breeders Store will not be liable if the My Breeders Store Platform, the Pet and/or Pet Accessories or any other ancillary products or services made available to you on, or via, the My Breeders Store Platform, are unavailable for any reason, including directly or indirectly as a result of:

   a) telecommunications unavailability, interruption, delay, bottleneck, failure or fault;

   b) negligent, malicious or wilful acts or omissions of third parties (including Breeders Store's third party service providers, Breeders and/or Suppliers);

   c) maintenance (scheduled or unscheduled) carried out by Breeders Store's or any third party service provider, including in respect of any of the systems or network used in connection with the provision of the My Breeders Store Platform;

   d) products or services provided by third parties (including internet service providers, Breeders and/or Suppliers) ceasing or becoming unavailable; or

   e) a Force Majeure Event.

4. You warrant, acknowledge and agree that:

   a) your use of the My Breeders Store Platform has not been made on the basis of any representations made by Breeders Store regarding future features or functionality of the My Breeders Store Platform;

   b) you (as a Breeder and/or Supplier) have the right to post, publish and reproduce the Breeder Content listed or displayed on the Breeder Profile (and all Intellectual Property Rights contained therein), and to grant us the right to post, publish and reproduce the Breeder Content on the My Breeders Store Platform or any of our social media pages;

   c) you have the full power and authority to enter into this agreement and perform your obligations contemplated by this agreement, and in doing so, you will not breach any third party rights; and

   d) Breeders Store makes no representations, warranties or guarantees that you (as a Breeder and/or Supplier) will make any profit, revenue, a minimum number of sales or increase your market share or client base by accessing, using and advertising on, or via, the My Breeders Store Platform.

## 16. Limitation of Liability

1. To the fullest extent permitted by Relevant Laws, Breeders Store will not be liable to you or any third party for Consequential Loss arising out of, relating to or connected to the provision of Pets, the supply or use of Pet Accessories, access to or use of the My Breeders Store Platform (or any other ancillary products or services made available to you on, or via, the My Breeders Store Platform) and this agreement, regardless of the cause of action on which they are based, even if advised of the possibility of such damage occurring.

2. Under no circumstances will Breeders Store's aggregate liability, whether based upon warranty, contract, statute, tort (including negligence) or otherwise, exceed:

   a) in the case of Buyers, the Purchase Price paid by you to Breeders Store; or

   b) in the case of Breeders/Suppliers, the Service Fees paid by you to Breeders Store,

   in relation to the Pets and/or Pet Accessories subject of the Claim.

3. You agree to defend, indemnify and hold Breeders Store, our Affiliates and our Personnel (collectively, the **Indemnified**) harmless from and against any and all Losses and Claims that may be brought against the Indemnified or which the Indemnified may pay, sustain or incur as a direct or indirect result of, or arising out of:

   a) the access and use by you, your Personnel, your Affiliates or any third party of the My Breeders Store Platform;

   b) a breach by you, your Personnel or your Affiliates of any Relevant Laws or the Intellectual Property Rights of Breeders Store or any third party;

   c) any breach by you or your Personnel of any Relevant Laws; or

   d) any breach by you, your Personnel or your Affiliates of this agreement.

## 17. Privacy

1. All Personal Information you provide to open a Account, create a Breeder Profile and any Breeder Content you upload, generate, share, or publish on, or via, the My Breeders Store Platform, is subject to our Privacy Policy, which is incorporated into this agreement. Breeders Store will not sell your Breeder Content or registration information to any third party.

2. You warrant that, in relation to any Personal Information comprising your Account, Breeder Profile, Breeder Content or any other information disclosed to Breeders Store on, or via, the My Breeders Store Platform:

   a) it has been collected in accordance with Privacy Laws;

   b) you have the authority to upload, post, publish or otherwise transmit such information on, or via, the My Breeders Store Platform; and

   c) you have obtained the informed consent of the individuals who are the subject of such Personal Information in order for Breeders Store to use, disclose, store, transfer, process or handle it.

## 18. Termination

1. During the Term you may terminate this agreement at any time by selecting the 'delete account' function in the profile setting or this agreement will automatically terminate at the end of the billing cycle if you (as a Breeder and/or Supplier) do not pay the Subscription Fee in advance for the next billing period. If you terminate the agreement in accordance with this clause, you (as a Breeder and/or Supplier) will not be entitled to a pro rata refund of the Subscription Fees for the unused portion of the Term.

2. Breeders Store may terminate the agreement any time (without liability to you except Breeders Store will provide the Breeder/Supplier with a pro rata refund of the Subscription Fees for the unused portion of the Term) by giving you 14 days' written notice.

3. Either party may terminate this agreement by giving notice with immediate effect if the other party:

   a) breaches any material term of this agreement and such breach is not remedied within five Business Days after receiving notice of the breach;

   b) breaches any material term of this agreement and such breach is not capable of remedy; or

   c) suffers an Insolvency Event.

4. You agree that Breeders Store may, at any time and at its sole discretion, with or without cause or any notice to you, terminate, suspend or block your access to (either temporarily or permanently):

   a) your Account, and/or Breeder Profile and/or Breeder Content;

   b) the My Breeders Store Platform;

   c) any social media pages linked to its business or the My Breeders Store Platform; or

   d) any other products and services offered on, or via the My Breeders Store Platform (including third party products and services).

5. Cause for such suspension or termination under clause 18.4 may include, but are not limited to:

   a) any serious or repeated breaches or violations of this agreement, our policies and guidelines (including our Privacy Policy and Breeder Content Policy) and any other agreements entered into between the parties;

   b) serious or repeated breaches or violations of Breeders Store or another person's Intellectual Property Rights or privacy rights;

   c) your failure (as a Breeder and/or Supplier) to pay the Subscription Fees to access and use the My Breeders Store Platform (except during the Trial Period);

   d) your activities, conduct or transactions on, or, via, the My Breeders Store Platform, brings, or has the capacity to bring, Breeders Store into disrepute;

   e) requests by law enforcement or other government agencies.

6. If Breeders Store terminates the agreement in accordance with clause 18.4, you acknowledge and agree that you are not entitled to pro rata refund of the Subscription Fees for the unused portion of the Term.

7. You agree that all such suspensions or terminations shall be made at Breeders Store's sole discretion and that Breeders Store shall not be liable to you or any third party for any such suspension or termination.

## 19. Effect of termination

1. On termination of this agreement for any reason:

   a) all licences and rights of access granted under this agreement will immediately terminate;

   b) you must pay all outstanding Subscription Fees, Service Fees and/or Purchase Prices (as the context permits) and any other outstanding monies owing to Breeders Store under this agreement within seven days; and

   c) Breeders Store will disable your Breeder Account or Buyer Account (as the context permits) and your Breeder Profile and your Breeder Content will no longer be accessible by you through the My Breeders Store Platform. All information that is no longer needed will be securely destroyed or de-identified; however, Breeders Store will be required to retain Personal Information (including basic registration information) in accordance with documentation retention and destruction laws.

## 20. Third party websites

1. The My Breeders Store Platform or any Breeder Profile may contain third party content or content that may link to other websites, services, products or resources on the Internet, and other websites, services, products or resources may contain links to the My Breeders Store Platform. These websites are not under our control and are not maintained by us. We are not responsible for the content of those websites, resources, products or services, and accept no responsibility for them or for any loss or damage that may arise from your use of them (notwithstanding anything else contained herein). We only provide links to third party websites for your information and convenience.

2. Links, references or other connections to these websites, resources, products or services do not imply any endorsement of them or any association with their owners, operators or advertising material (as the context permits).

3. Your linking to or from these sites, or use of, or reliance on, such websites, resources, products or services is at your own risk. You should carefully review the terms and conditions and privacy policies of all off-site pages and other websites or third-party suppliers that you visit or transact with.

## 21. Dispute Resolution

The parties may, before resorting to court proceedings (except interlocutory or interim relief), refer any dispute between the parties under or relating to this Agreement initially to a nominated representative of each party to endeavour to resolve the dispute within 20 days. If the dispute is not resolved within this period, then either party may, in its absolute discretion, initiate court proceedings.

## 22. Force Majeure

Breeders Store will have no liability to you (whether you are a Buyer, Breeder or Supplier) or anyone else under this agreement if it is prevented from or delayed in performing its obligations under this agreement or otherwise, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes, failure of a utility service or transport or telecommunications network, act of God, natural disasters, pandemics, epidemics, mandatory government lock-downs, sabotage, war, riot, civil commotion, computer hacking, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of hosting or data centre providers or other suppliers or sub-contractors, shortage of suppliers, equipment and materials, (**Force Majeure Event**).

## 23. Updates

1. Without notice to you, Breeders Store may, at our absolute discretion, from time to time:

   a) change, add or delete the functions, features, performance, or other characteristics of the My Breeders Store Platform; or

   b) apply or install updates to, or new versions of, the My Breeders Store Platform.

2. You acknowledge that the Content and Breeder Content on the My Breeders Store Platform are subject to change at any time and may be out of date at any given time. Breeders Store are under no obligation to:

   a) update, correct or fix any Content, Breeder Content or errors in the My Breeders Store Platform; and/or

   b) notify you of any changes to the Content, Breeder Content or the My Breeders Store Platform unless required by a Relevant Law to do so.

## 24. Notice

1. By accessing and using My Breeders Store Platform, you accept that communication with us will be mainly electronic. We will contact you by email or provide you with information by posting notices on the My Breeders Store Platform.

2. You acknowledge that all contracts, notices, information and other communication (**notices**) we may provide electronically comply with any legal requirements that such documents be in writing.

3. Notice will be deemed received and properly served immediately when posted on the My Breeders Store Platform or 24 hours after an email is sent to you. As proof of service, it is sufficient that the email was sent to the email address specified by you in the Registration Portal.

## 25. General

1. A waiver of any right under this agreement is only effective if it is in writing, and it applies only to the party to whom the waiver is addressed and to the circumstances for which it is given.

2. If any provision (or part of a provision) of this agreement is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable or illegal, the other provisions shall remain in force.

3. If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties.

4. Breeders Store may deduct from any amounts owing to you under this agreement from any amounts due or owing to us under this agreement (including under the indemnity in clause 16.3).

5. This agreement, and any documents referred to in it, constitute the whole agreement between the parties and supersede any previous arrangements, understandings or agreements between them relating to the subject matter they cover.

6. Neither party shall, without the prior written consent of the other party (which will not be unreasonably withheld), assign, transfer or deal in any other manner with all or any of its rights or obligations under this agreement, except that Breeders Store may assign, sell or transfer our rights or obligations under this agreement to an Affiliate or bona fide third party purchaser of our business.

7. Clauses 11, 12, 14, 15, 16, 17, 18, 19, 21, 22, 24, 25 and any other obligation which is expressed to, or by its nature, survive expiry or termination of this agreement, will survive expiry or termination of this agreement and are enforceable at any time at law or in equity.

8. Nothing in this agreement is intended to or shall operate to create a partnership between the parties, or authorise either party to act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind the other in any way.

9. The laws of the state of Victoria, Australia govern this agreement. You agree to submit to the exclusive jurisdiction of the courts of Victoria.

## 26. Contact Us

If you have any questions about this agreement, please contact us via email at hello@mybreedersstore.com.

## 27. Definitions

1. In this agreement, the following words shall have the following meanings:

   a) **Account** has the meaning in clause 5.2.

   b) **Affiliate** means in relation to any party, a person which, directly or indirectly, (i) is Controlled by that party; or (ii) Controls that party; or (iii) is Controlled by a person referred to in (ii) above, and for this purpose **Control** means the power of a person to secure (whether by the holding of shares, possession of voting rights or by virtue of any powers conferred by articles of association, constitution, partnership agreement or other document regulating such person) that the affairs of another are conducted in accordance with its wishes.

   c) **Breeder** means a user who: (i) is licensed or authorised to breed, rear and sell Pets under a Breeding Law; (ii) advertises and sells Pets on, or via, the My Breeders Store Platform; and (iii) who has created a Breeder Profile.

   d) **Breeder Account** has the meaning in clause 5.1(a).

   e) **Breeder Store** has the meaning in clause 1.1, and where the context permits, our Personnel and Affiliates.

   f) **Breeders Store IP** has the meaning in clause 14.1.

   g) **Breeder Laws** means any relevant laws, regulations, codes of conduct or rules in Australia in relation to breeding, rearing, storing, sheltering, sale or distribution of Pets (including, but not limited to, the *Domestic Animal Act 1994 (Vic), Domestic Animal Regulations 2015 (Vic)* *and Code of practice for the operation of breeding and rearing businesses 2014 (revised 2018*)).

   h) **Breeder Profile** means the profile of a Breeder and/or Supplier that is displayed on the My Breeders Store Platform and which contains the Breeder's and/or Supplier's business information and registration information (including Personal Information) as further described in clause 7.1.

   i) **Business Days** means each day excluding Saturdays, Sundays and public holidays in Victoria.

   j) **Buyer Account** has the meaning in clause 5.1(b).

   k) **Buyer** means a user who orders and/or purchases Food Products from Partners on, or via, the Aircooks Platform and who creates a Buyer Profile.

   l) **Buyer** means a user who orders or purchases Pets and/or Pet Accessories on, or via, the My Breeders Store Platform.

   m) **Claim** means any allegation, debt, cause of action, liability, claim, proceeding, suit or demand of any nature whether present or future, fixed or unascertained, actual or contingent, at law, in equity, under statute or otherwise.

   n) **Commencement Date** has the meaning in clause 4.1.

   o) **Consequential Loss** means the following, however arising and even if it is reasonably contemplated by the parties at the Commencement Date as a likely result of breach of the agreement: (i) direct, indirect, consequential, incidental, special, remote or unforeseeable loss, damage, cost or expense; (ii) loss of revenue, profit, income, bargain, opportunity, use, production, customers, business, contract, goodwill, or anticipated savings, loss caused by business interruption, or the cost of obtaining new financing or maintaining existing financing, loss of or corruption to data, loss of use of data, or loss of enjoyment; (iii) costs or expenses incurred to prevent or reduce loss or damage which otherwise may be incurred or suffered by a third party; (iv) loss or damage to any property, or for any personal injury or death; or (v) loss or damage of the nature set out above in clauses (i) to (iv) (inclusive) that is incurred or suffered by or to a third party.

   p) **Content** means all the information, text, materials, graphics, logos, button icons, images, video and audio clips, trade marks (whether registered or not), layout, arrangement, graphical user interface, look and feel, databases, functionality and control features of the My Breeders Store Platform.

   q) **Force Majeure Event** has the meaning in clause 22.

   r) **GST** has the meaning in the *A New Tax System (Goods and Services Tax) Act 1999 (Cth*).

   s) **Insolvency Event** means a liquidation or winding up, the appointment of a controller, administrator, receiver, manager or similar insolvency administrator to a party or any substantial part of its assets or any event that has a substantially similar effect to the above events.

   t) **Intellectual Property Rights** means designs, copyright, trade marks, patents, operations, software or systems, trade names and domain names, rights in goodwill, rights in confidential information, databases or other intellectual property rights, whether under statute, common law, equity, custom or usage, and whether registered or unregistered, and including all applications for, and renewals or extensions of, such rights and all similar rights which subsist or will subsist now or in the future in any part of the world.

   u) **Listing** has the meaning in clause 7.8.

   v) **Loss** means loss, damage, liability, charge, expense, outgoing, payment or cost of any nature or kind, including all legal and other professional costs including Consequential Loss.

   w) **My Breeders Store Platform** means the online marketplace (including all Intellectual Property Rights contained therein) owned and operated by Breeders Store and made available to Breeders, Suppliers and Buyers via a mobile application and/or the Site.

   x) **Password** has the meaning in clause 5.2.

   y) **Personal Information** has the same meaning that it has under Privacy Laws, namely information or an opinion about an identified individual or an individual who is reasonably identifiable, whether the information or opinion is true or not and recorded in a material form or not.

   z) **Privacy Laws** means any applicable laws and codes of practice dealing with privacy, including the *Privacy Act* 1988 (Cth) (as amended), the Australian Privacy Principles and any other legislation, codes and policies relating to the handling of Personal Information.

   aa) **Personnel** means any officer, employee, agent, contractor, sub-contractor or consultant of a party.

   bb) **Pet** means domestic animal (including, but not limited to, dogs, cats, guinea pigs, rabbits and hamsters).

   cc) **Pet Accessories** means toys, equipment, food, clothing, collars, grooming accessories, soaps, shampoos and conditioners, brushes, harnesses, leads, blankets, beds, mats and gates relating to, or used in connection with Pets.

   dd) **Privacy Policy** means our privacy policy available at www.mybreedersstore.com or any other internet site notified by Breeders Store from time to time, which is incorporated into this agreement.

   ee) **Purchase Price** has the meaning in clause 11.2.

   ff) **Registration Portal** means the registration portal on the Site or mobile application used by you to access, user and/or subscribe to the My Breeders Store Platform.

   gg) **Relevant Laws** means any relevant rules of common law, principles of equity, international, federal, state and local laws, statutes, rules, regulations, proclamations, ordinances and by-laws and other subordinate legislation, rulings, or legal requirements anywhere in the world, and includes Privacy Laws and Breeding Laws in the state or territory of Australia in which the Breeder operates.

   hh) **Term** has the meaning in clause 4.1.

   ii) **Service Fee** has the meaning in clause 11.3.

   jj) **Subscription Fee** has the meaning in clause 11.1.

   kk) **Supplier** means a user who: (i) sells Pet Accessories on, or via, the My Breeders Store Platform; and (ii) has created a Breeder Profile.

   ll) **Site** means the Breeders Store website located at www.mybreedersstore.com or any other internet site notified by Breeders Store from time to time.

   mm) **Trial Period** means 30 consecutive days from the date you register and setup your Breeder Account.

   nn) **Third Party Payment Processor** means Stripe and any other third party payment processor permitted by Breeders Store from time to time.

   oo) **Breeder Profile** means the profile of a Breeder or Supplier (as the context permits) that is displayed on the My Breeders Store Platform and which contained the business and registration information (including Personal Information) as further described in clause 7.1 and Breeder Content.

   pp) **you** or **your** has the meaning in clause 1.1 and where the context permits, is a Buyer, Supplier or Breeder.

   `;