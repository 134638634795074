// privacy-policy.ts
export const privacyPolicyContent = String.raw`# Privacy Policy

# Privacy Policy

## 1. Introduction

1. This website ([www.mybreedersstore.com](http://www.mybreedersstore.com)) and our mobile application (collectively, the **My Breeders Store Platform**) are created and controlled by The Breeders Store Pty Ltd (ACN 655 079 137) (**we**, **us** or **our**).

2. We are committed to ensuring your Personal Information is protected. We manage your Personal Information in accordance with the Australian Privacy Principles set out in the _Privacy Act 1988_ (Cth) (**Privacy Act**).

3. The My Breeders Store Platform is a portal or online marketplace through which Buyers can order and purchase Pets and Pet Accessories made available by Breeders or Suppliers on, or via, the My Breeders Store Platform. By accessing and using the My Breeders Store Platform or services you agree to this Privacy Policy.

4. This Privacy Policy outlines how we collect, store, use and disclose your Personal Information, and how you may access your Personal Information kept by us or how you may make a privacy complaint.

5. For the purposes of this Privacy Policy, **Buyers**, **Breeders**, **Pets**, **Pet Accessories** and **Suppliers** have the meanings as provided in the My Breeders Store Platform Terms and Conditions.

## 2. The information we collect about you

1. **Personal information** has the same meaning that it has under the Privacy Act, namely, information means information or an opinion about an identified individual or an individual who is reasonably identifiable, whether the information or opinion is true or not and recorded in a material form or not.

2. We will only collect and hold Personal Information about you that is reasonably necessary to undertake our business activities and functions, and which is required to deliver the My Breeders Store Platform, Pets, Pet Accessories and other products and services to you, or as otherwise permitted by law.

3. The type of Personal Information and Non-Personal Information that we collect and use depends on the type of dealings that you have with us and includes the following:

    a) contact details (for example, full name, address, mobile and telephone numbers and email address, profile picture, username and password);

    b) business information and contact details of your authorised representative (for example, business name, Australian Business Number, business address, work mobile and office telephone numbers and work email address, profile picture or logo and Breeder ID);

    c) registration information provided by you to create and update your account on the My Breeders Store Platform (such as your username and password);

    d) information relating to the Pets and Pet Accessories promoted or advertised by Breeders and/or Suppliers on, or via, the My Breeders Store Platform;

    e) information relating to the Pets and/or Pet Accessories a Buyer has made enquiries about or has purchased from Breeders and/or Suppliers on, or via, the My Breeders Store Platform (including the Buyer's delivery address);

    f) information relating to your dealings, or enquiries you have made, with us in relation to the My Breeders Store Platform and our other products and services;

    g) payment and billing information;

    h) information regarding your access and use of the My Breeders Store Platform, including location information, your computer's internet protocol (IP) address, unique device identifier, browser characteristics, device characteristics, operating system, language preferences, referring URLs, information on actions taken on the My Breeders Store Platform, dates and times of visits to the My Breeders Store Platform and other usage statistics;

    i) other information that you provide to us or that we may collect in the course of our relationship with you.

4. We do not collect your Sensitive Information (as defined by the Privacy Act).

## 3. How we collect Personal Information

1. We will collect Personal Information and Non-Personal Information about you in a number of different ways. We may collect Personal Information and Non-Personal Information directly from you or in the course of our dealings with you. For example:

    a) when you access and use the My Breeders Store Platform either as a Breeder and/or Supplier or Buyer (including when you open an account, create or update your profile or add information to your account on, or, via, the My Breeders Store Platform);

    b) when Breeders and/or Suppliers publish, advertise, list or post Pets and/or Pet Accessories for sale on, or via, the My Breeders Store Platform;

    c) when Buyers order and purchase Pets and/or Pet Accessories advertised or offered for sale on, or via, the My Breeders Store Platform;

    d) when receiving email communications or when we post notices on, or via, the My Breeders Store Platform;

    e) when you visit the My Breeders Store Platform, contact us online or via telephone or email with a query or request, or make a comment on our social media sites;

    f) from third parties (including our related bodies corporate, business partners and service providers), other Buyers, Breeders or Suppliers and from publicly available sources of information;

    g) when otherwise legally authorised or required to do so.

2. When we collect Personal Information directly from you, we will take reasonable steps to notify you (using a collection notice) at, before, or as soon as practicable after, the time of collection.

## 4. How we use your Personal Information

We use and disclose your Personal Information and Non-Personal Information for the purposes for which the information is collected, including (but not limited to):

a) providing or delivering the My Breeders Store Platform, products and services to you. For example, by:

i) enabling Buyers to order and purchase Pets and/or Pet Accessories from Breeders and/or Suppliers on, or via, the My Breeders Store Platform;

ii) enabling Breeders and/or Suppliers to publish, post, advertise or list their Pets and/or Pet Accessories for sale on, or via, the My Breeders Store Platform and to sell their Pets and/or Pet Accessories on, or via, the My Breeders Store Platform;

iii) enabling Buyers, Breeders and Suppliers to access and use other products and services we may offer from time to time.

b) assisting with, or responding to, your queries or complaints regarding the My Breeders Store Platform;

c) informing you about the My Breeders Store Platform or services, or other matters which we believe are of interest to you (such as recruitment or job opportunities);

d) share with our Third Party Providers;

e) administer, improve and manage the My Breeders Store Platform;

f) charge and bill you for the use of the My Breeders Store Platform, products and services;

g) for direct marketing purposes (see section 6 below);

h) complying with our legal and regulatory obligations.

## 5. Disclosure to Third Parties

1. In order to provide or deliver My Breeders Store Platform, products and services to you we may disclose your Personal Information to:

    a) our related bodies corporate, business partners, service providers, third party contractors, agents or suppliers, external business advisors or other authorised external service providers who perform functions on our behalf (such as credit reporting bodies, credit providers, third party payment processors, hosting companies, web developers, internet service providers, telecommunication providers, customer service providers, customer support specialists, fulfilment companies, external business advisors (such as auditors and lawyers), government agencies, public registries, search agencies, regulatory and licensing bodies, parties to whom you refer us to (for example, previous employers and referees), recruitment agencies and from publicly available sources of information (for example, online databases and social media)) (collectively, **Third Party Providers**);

    b) law enforcement bodies or regulatory authorities to assist with their functions, or as otherwise required or authorised by law.

2. We use a third party payment processor to process payments made to us. In connection with the processing of such payments, we do not collect, process, use, share, store or disclose any Personal Information or payment information (such as credit card and bank account details). Rather, all such information is provided directly to our third party processor, Stripe, whose use of your Personal Information is governed by their privacy policy, which may be viewed at www.stripe.com/en-au/privacy.

3. Prior to the disclosure of Personal Information to Third Party Providers, we will take such steps as reasonable in the circumstances to ensure that the Third Party Providers treat your Personal Information securely and otherwise complies with the relevant Australian Privacy Principles in relation to the Personal Information.

## 6. Marketing communications

1. We may use and disclose your Personal Information to send you information about the My Breeders Store Platform, Pets and/or Pet Accessories offer for sale by Breeders and/or Suppliers and other products or services we may offer from time to time, as well as other information that may be of interest to you. We may send this information in a variety of ways, such as by mail, email, SMS, telephone, social media or by customising online content and displaying advertising on our My Breeders Store Platform.

2. If you do not wish to receive any of these marketing communications, you can opt out by following the unsubscribe instructions included in the relevant marketing communication, or by contacting us using the contact details set out in section 12 below.

## 7. Overseas disclosures of Personal Information

1. In the course of providing you with access to and use of the My Breeders Store Platform and services, it may become necessary or desirable to disclose Personal Information to Third Party Providers located overseas. The countries in which these overseas recipients may be located will depend upon the individual circumstances. However, in the course of our ordinary business operations we commonly disclose Personal Information to Third Party Providers located outside of Australia. For example, in the course of processing payments made to us or to another use of the My Breeders Store Platform, we commonly disclose Personal Information (including payment information) to our third party payment processors located in the United States and elsewhere in the world.

2. The laws where these overseas recipients may be located provide various levels of protection for Personal Information which are not always equivalent to the level of protection that may be provided for in Australia. Where we transfer your Personal Information overseas we will take reasonable steps to ensure that your Personal Information is treated securely and the means of transfer provides adequate safeguards.

## 8. Notifiable Data Breaches Scheme

In the event of any loss, or unauthorised access or disclosure of your Personal Information that is likely to result in serious harm to you, we will investigate and notify you and the Australian Information Commissioner as soon as practicable, in accordance with notifiable data breach scheme contained in Part IIIC of the Privacy Act.

## 9. Security of your Personal Information

1. We take steps reasonable in the circumstances to ensure that the Personal Information it holds is protected from misuse, interference and loss and from unauthorised access, modification or disclosure.

2. The transmission of information via the Internet is not completely secure. We cannot guarantee the security of your data transmitted to our online services and any transmission is at your own risk.

3. We will destroy or de-identify Personal Information in circumstances where it is no longer required, unless we are otherwise required or authorised by law to retain the information.

## 10. Accessing and correcting your Personal Information

1. We take steps reasonable in the circumstances to ensure Personal Information we hold is accurate, up-to-date, complete, relevant and not misleading. Under the Privacy Act, you have a right to access and seek correction of your Personal Information that is collected and held by us.

2. If at any time you would like to access or correct the Personal Information we hold about you, or you would like more information about our approach to privacy, please contact us via the contact details set out in section 12 below. If we refuse your request to access or correct your Personal Information, we will provide you with written reasons for the refusal and details of complaint mechanisms.

## 11. Cookies

We use small text files placed on your computer called 'cookies' to collect standard log and visitor behaviour information in an anonymous form. We use this information to analyse how visitors use our website and to compile statistical reports on website activity. You can set your computer to reject cookies, but this may affect your ability to use certain parts of the My Breeders Store Platform.

## 12. Privacy complaints

If you have any complaints or issues you wish to raise with us regarding the way we have handled your Personal Information, or would like to discuss any issues about our Privacy Policy, please contact us directly by email at hello@mybreedersstore.com.

## 13. Changes to this Privacy Policy

From time to time it may be necessary for us to review and revise our Privacy Policy. We may notify you about changes to this Privacy Policy by posting an updated version on the My Breeders Store Platform. We encourage you to check the My Breeders Store Platform from time to time to ensure you are familiar with our latest Privacy Policy.

`;
