import React from 'react';
import { privacyPolicyContent } from './privacy-policy';
import './PrivacyPolicypage.scss';
import { Card } from 'antd';
import { getToken } from '../../utils/authHelpers';
import ProtectedRoute from '../../utils/ProtectedRoute';
import PrivateLayout from '../../layouts/private/PrivateLayout';
import PublicLayout from '../../layouts/public/PublicLayout';
import ReactMarkdown from 'react-markdown';
import legalImg from '../../assets/images/privacy_policy.svg';
import { PageHeaderLegal } from '../termsAndConditions/TermsAndConditionsPage';

const PrivacyPolicypage: React.FC = () => {
  const loggedIn: boolean = getToken() !== '';

  const renderContent = () => {
    if (loggedIn) {
      return (
        <ProtectedRoute>
          <PrivateLayout className="privateTermsAndConditionsPage">
            <Card>
              <PageHeaderLegal title="Privacy Policy" img={legalImg} />
              <ReactMarkdown className="richtextContent">{privacyPolicyContent}</ReactMarkdown>
            </Card>
          </PrivateLayout>
        </ProtectedRoute>
      );
    }
    return (
      <PublicLayout navbar className="publicTermsAndConditionsPage">
        <Card>
          <PageHeaderLegal title="Privacy Policy" img={legalImg} />
          <ReactMarkdown className="richtextContent">{privacyPolicyContent}</ReactMarkdown>
        </Card>
      </PublicLayout>
    );
  };

  return renderContent();
};

export default PrivacyPolicypage;